import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/todoimg.jpeg'

// import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: IMG4,
    title: 'To-Do Application',
    github: 'https://github.com/asheeque/spring-todo-backend',
    demo: 'https://todo.asheequecm.me'
  },
  {
    id: 2,
    image: IMG1,
    title: 'React workspace',
    github: 'https://github.com/asheeque/React-workspace',
    // demo: 'https://dribbble.com/shots/16673715-Crypto-currency-dashboards-and-financial-data-visualization'
  },
  {
    id: 3,
    image: IMG2,
    title: 'Computer vision',
    github: 'https://github.com/asheeque/Computer-vision',
    // demo: 'https://dribbble.com/shots/16580766-Orion-UI-kit-Charts-templates-infographics-in-Figma'
  },
  {
    id: 4,
    image: IMG3,
    title: 'Raft implementation',
    github: 'https://github.com/asheeque/raft',
    // demo: 'https://dribbble.com/shots/17290917-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps'
  }
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} style={{height:"271px"}} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className='btn' target='_blank'>Github</a>
                {!!demo &&(
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                )}
                
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio